import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import {
    chatEndpoint,
    useCreateConversationMutation,
    useGetConversationQuery,
    useLazySendMessageQuery,
} from '../../../api/endpoints/chat/chat-endpoint';
import { usageMetricsEndpoint } from '../../../api/endpoints/usage-metrics-endpoint';
import { ChatType, MessageRole, MessageStatus, UsageMetricsEventType } from '../../../api/types';
import { getActiveFilters } from '../../../components/Modals/SourcesFilter/helpers';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getActiveSmartFiltersIds, getActiveSources } from '../../../utils/transforms';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch } from '../app-hooks';
import { useDatesFilter, useProject, useSmartFilters, useSources } from '../settings-hooks';

const finishedStatus = [MessageStatus.DONE, MessageStatus.FAILED, MessageStatus.ABORTED];
export interface ChatArgs {
    actionId?: string;
    chatType: ChatType;
}

export const useChat = (args: ChatArgs) => {
    const { actionId: action_id, chatType: chat_type } = args ?? {};
    const { project: customer_project_id } = useProject();
    const { sources } = useSources();
    const activeSources = getActiveSources(sources);
    const { createDatesFilter } = useDatesFilter();
    const { smartFilters, canUseSmartFilters } = useSmartFilters();

    const dispatch = useAppDispatch();
    const { data: conversation, isLoading: isGetConversationLoading } = useGetConversationQuery(
        {
            customer_project_id,
            action_id,
        },
        { skip: !customer_project_id || (chat_type === ChatType.APP_CHAT && !action_id) }
    );
    const [createConversation, { isLoading: isCreateConversationLoading }] = useCreateConversationMutation();
    const [triggerSendMessage, { originalArgs }] = useLazySendMessageQuery();

    const setupConversation = useCallback(async () => {
        await createConversation({ customer_project_id, action_id, chat_type });
    }, [customer_project_id, action_id, chat_type, createConversation]);

    const isGenerating = useMemo(() => {
        const message = conversation?.messages[conversation?.messages.length - 1];
        return message?.status && !finishedStatus.includes(message?.status);
    }, [conversation?.messages]);

    useEffect(() => {
        if (conversation === null) {
            setupConversation();
        }
    }, [conversation, setupConversation]);

    const updateMessage = useCallback(
        (message_id: string, content: string) => {
            dispatch(
                chatEndpoint.util.updateQueryData('getConversation', { action_id, customer_project_id }, (draft) => {
                    const messageToUpdate = draft.messages.find((m) => m.message_id === message_id);
                    if (!messageToUpdate) {
                        return;
                    }
                    messageToUpdate.content = content;
                })
            );
        },
        [dispatch, action_id, customer_project_id]
    );

    const sendMessage = useCallback(
        async (content: string) => {
            if (!conversation || isGenerating) {
                return;
            }
            const { conversation_id } = conversation;

            const turn_id = uuid();

            dispatch(
                chatEndpoint.util.updateQueryData('getConversation', { action_id, customer_project_id }, (draft) => {
                    draft.messages.push(
                        // Append user question
                        { message_id: uuid(), turn_id, content, role: MessageRole.USER, status: MessageStatus.DONE },
                        // Placeholder for the generated assistant message
                        {
                            message_id: uuid(),
                            turn_id,
                            content: '',
                            is_placeholder: true,
                            role: MessageRole.ASSISTANT,
                            status: MessageStatus.RUNNING,
                        }
                    );
                })
            );
            const controller = new AbortController();
            const selectedSmartFiltersIds = canUseSmartFilters ? getActiveSmartFiltersIds(smartFilters) : undefined;
            const askFilters = getActiveFilters({ dates: createDatesFilter() });

            await triggerSendMessage({
                content,
                action_id,
                conversation_id,
                customer_project_id,
                controller,
                selected_sources: activeSources,
                selected_smart_filters_ids: selectedSmartFiltersIds,
                filters: askFilters,
            });
        },
        [
            triggerSendMessage,
            createDatesFilter,
            dispatch,
            activeSources,
            canUseSmartFilters,
            smartFilters,
            isGenerating,
            conversation,
            customer_project_id,
            action_id,
        ]
    );

    const clearChat = useCallback(async () => {
        await setupConversation();
    }, [setupConversation]);

    const abortMessage = useCallback(() => {
        const message = conversation?.messages.find((m) => m.status === MessageStatus.RUNNING);

        if (!message) {
            return;
        }

        const meta = {
            conversationId: conversation?.conversation_id,
            chatType: chat_type,
            turnId: message.turn_id,
            messageId: message.message_id,
        };

        dispatch(
            sendMetrics({
                event: MixpanelEvent.CHAT_ABORT_MESSAGE,
                meta,
            })
        );

        dispatch(
            usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                type: UsageMetricsEventType.CHAT_MESSAGE_ABORT,
                meta: {
                    ...meta,
                    output: message.content,
                    timestamp: Date.now(),
                },
            })
        );

        originalArgs?.controller?.abort();
    }, [chat_type, conversation, originalArgs?.controller, dispatch]);

    return useMemo(
        () => ({
            conversation,
            sendMessage,
            clearChat,
            updateMessage,
            abortMessage,
            isGenerating,
            isLoading: isGetConversationLoading || isCreateConversationLoading,
        }),
        [
            isGenerating,
            isGetConversationLoading,
            isCreateConversationLoading,
            conversation,
            clearChat,
            updateMessage,
            sendMessage,
            abortMessage,
        ]
    );
};

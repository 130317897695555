import React from 'react';
import { useTranslation } from 'react-i18next';

import { IDirectAnswerResult } from '../../../../../api/types';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy-answer-action.svg';
import { ReactComponent as CopyWithAttributionsIcon } from '../../../../../assets/icons/copy-answer-w-attributions-action.svg';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { useCopyDirectAnswer } from '../../../../../redux/hooks/copy-direct-answer';
import { sendDirectAnswerMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from '../MessageActions.module.scss';

export interface ICopyChatActionProps {
    includeAttributionLinks?: boolean;
    directAnswer: IDirectAnswerResult;
}

export const CopyChatAction: React.FC<ICopyChatActionProps> = ({ directAnswer, includeAttributionLinks = false }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const copyAnswer = useCopyDirectAnswer();
    const labelTransKey = includeAttributionLinks ? 'button.copy.answer-w-attributions' : 'button.copy.answer';
    const Icon = includeAttributionLinks ? CopyWithAttributionsIcon : CopyIcon;

    const handleCopyButtonClick = async () => {
        const exclude = new Map([['attributions', !includeAttributionLinks]]);
        await copyAnswer(directAnswer, exclude);
        const event = includeAttributionLinks
            ? MixpanelEvent.DIRECT_ANSWER_COPY_WITH_ATTRIBUTION
            : MixpanelEvent.DIRECT_ANSWER_COPY;

        const { answer, action_type, question_id } = directAnswer;
        dispatch(
            sendDirectAnswerMetrics(event, {
                answer,
                action_type,
                question_id,
            })
        );
    };

    return (
        <Tooltip label={t(labelTransKey)} placement="top-end" hasArrow>
            <Icon onClick={handleCopyButtonClick} className={styles.action} />
        </Tooltip>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useInterval } from '@chakra-ui/react';

interface TypeWriterProps {
    delay: number;
    text: string;
    onDone: () => void;
    enabled: boolean; // if false, the typewriter effect will not be applied and the text will be returned as is
}

export const useTypeWriterEffect = ({ text, delay, onDone, enabled }: TypeWriterProps): string => {
    const [charsToShow, setCharsToShow] = useState<number>(0);

    const callback = useCallback(() => {
        // avoid React batching frequent state updates
        flushSync(() => {
            setCharsToShow((prev) => {
                if (prev === text.length) {
                    queueMicrotask(onDone);
                    return prev;
                }

                return prev + 1;
            });
        });
    }, [text, onDone, setCharsToShow]);

    const intervalDelay: number | null = enabled ? delay : null;

    useInterval(callback, intervalDelay);

    useEffect(() => {
        if (enabled) {
            setCharsToShow(0);
        }
    }, [enabled]);

    return enabled ? text.slice(0, charsToShow) : text;
};

import React, { FC, useCallback, useMemo } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import cx from 'clsx';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../api/consts';
import { useGetPreviewMutation } from '../../../api/endpoints/preview-endpoint';
import { IAttributionMeta, IAttributionsAnswer, IMessage, MessageRole, MessageStatus } from '../../../api/types';
import { ReactComponent as AskAILogo } from '../../../assets/icons/ask-ai-logo.svg';
import { useAppDispatch } from '../../../redux/hooks/app-hooks';
import { useProject } from '../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { previewAnswer } from '../../../redux/thunks/preview-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { Attribution } from '../../Answers/DirectAnswer/Description/Attribution/Attribution';
import { DirectAnswerMarkupRenderer } from '../../Answers/DirectAnswer/Description/MarkupRenderer/DirectAnswerMarkupRenderer';
import { AskAnExpert } from '../../AskAnExpert/AskAnExpert';

import styles from './ChatMessage.module.scss';

const CURSOR = '|';

interface ChatMessageProps extends IMessage {
    actions?: React.JSX.Element;
    userQuery?: string;
}

const finishedStatus = [MessageStatus.DONE, MessageStatus.FAILED, MessageStatus.ABORTED];

export const ChatMessage: FC<ChatMessageProps> = (props) => {
    const { content, role, actions, attributions, status, turn_id, userQuery } = props;
    const [getPreview] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const dispatch = useAppDispatch();
    const { project } = useProject();

    const applyCursor = (text: string) => {
        return `${text}<span class="${styles.cursor}">${CURSOR}</span>`;
    };

    const isAssistant = role === MessageRole.ASSISTANT;

    const dir = isAssistant ? 'ltr' : 'rtl';

    const isTyping = status && !finishedStatus.includes(status);

    const isFailed = status === MessageStatus.FAILED;

    const showCursor = isTyping && role === MessageRole.ASSISTANT;

    const contentToShow = showCursor ? applyCursor(content) : content;

    const isAttributionValid = (attribution: IAttributionMeta) =>
        Boolean(
            attribution.title &&
                attribution.url &&
                attribution.source_id &&
                attribution.source_icon &&
                attribution.chunk_id
        );

    const answersForAttributions: IAttributionsAnswer[] | null = useMemo(() => {
        if (!attributions || attributions.length === 0) {
            return null;
        }

        const answers: IAttributionsAnswer[] = attributions.filter(isAttributionValid).map((attribution) => ({
            title: attribution.title!,
            url: attribution.url!,
            source_id: attribution.source_id!,
            source_icon: attribution.source_icon!,
            chunk_id: attribution.chunk_id!,
            doc_id: attribution.doc_id,
            uuid: attribution.chunk_id!,
        }));

        return answers.length > 0 ? answers : null;
    }, [attributions]);

    const handleAttributionClick = useCallback(
        (answer: IAttributionsAnswer) => {
            const { doc_id, source_id, uuid } = answer;

            getPreview({
                doc_id,
                project,
                source_id,
                query: content,
            });

            dispatch(previewAnswer(answer));
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_ATTRIBUTION_CLICK,
                    meta: {
                        event: 'attribution_action',
                        use_shortcut: false,
                        turn_id,
                        chunk_id: uuid,
                    },
                })
            );
        },
        [dispatch, getPreview, project, content, turn_id]
    );

    return (
        <Box display="flex" gap={1} dir={dir}>
            {isAssistant ? (
                <Box>
                    <AskAILogo className={styles.avatar} />
                </Box>
            ) : null}
            <Box
                className={cx(styles.bubble, isAssistant ? styles.assistant : styles.user, {
                    [styles.failed]: isFailed,
                })}
                dir={dir}
            >
                <Box className={styles.content}>
                    <DirectAnswerMarkupRenderer className={styles.text}>{contentToShow}</DirectAnswerMarkupRenderer>
                </Box>
                {answersForAttributions ? (
                    <Attribution
                        disableDimmer
                        className={styles.attributions}
                        answers={answersForAttributions}
                        onAttributionClick={handleAttributionClick}
                    />
                ) : null}
                {!isTyping && !isFailed && isAssistant && (userQuery || actions) && (
                    <Box className={styles.actionsWrapper}>
                        <Divider className={styles.divider} />
                        <Box className={styles.actions}>
                            {!isFailed && actions}
                            {userQuery && (
                                <AskAnExpert
                                    hideIcon
                                    variant="chat-ask-an-expert"
                                    initialText={userQuery}
                                    id={turn_id}
                                />
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

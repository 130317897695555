import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { ReactComponent as AddToKbIcon } from 'answers-core/src/assets/icons/ask-ai-knowledgebase/add-to-kb-icon.svg';

type AddToKbVariants = 'answers-header-action';

export type AddKnowledgeButtonProps = {
    variant?: AddToKbVariants;
    className?: string;
    isDisabled?: boolean;
};

export const AddKnowledgeButton: React.FC<AddKnowledgeButtonProps> = ({ variant, className, isDisabled }) => {
    const { t } = useTranslation('translations');

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
    };

    return (
        <Button
            variant={variant}
            className={className}
            onClick={handleClick}
            isDisabled={isDisabled}
            leftIcon={<AddToKbIcon />}
        >
            {t('ask-ai-kb.add-to-kb')}
        </Button>
    );
};

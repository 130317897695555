import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';

import { ChatType, MessageRole } from '../../api/types';
import { ReactComponent as ClearChat } from '../../assets/icons/clear-chat.svg';
import { useChat } from '../../redux/hooks/answer/chat-hook';
import { Status } from '../Status/Status';
import { Tooltip } from '../Tooltips/Tooltip';

import { ChatInput } from './Input/ChatInput';
import { MessageActions } from './Message/Actions/MessageActions';
import { ChatMessage } from './Message/ChatMessage';

import styles from './Chat.module.scss';

export const Chat: React.FC = () => {
    const { t } = useTranslation('translations');
    const { isLoading, conversation, sendMessage, clearChat, abortMessage } = useChat({
        chatType: ChatType.CHAT_TAB,
    });
    const bottomRef = useRef<HTMLDivElement>(null);
    const isInitialScrollPerformed = useRef<boolean>(false);

    useLayoutEffect(() => {
        // Scroll to the bottom of the chat box when the component mounts
        if (bottomRef.current && conversation.length) {
            const isScrolledToBottom = bottomRef.current.getBoundingClientRect().bottom <= window.innerHeight;
            if (isScrolledToBottom || !isInitialScrollPerformed.current) {
                bottomRef.current.scrollIntoView({ behavior: 'auto' });
                isInitialScrollPerformed.current = true;
            }
        }
    }, [conversation, isInitialScrollPerformed]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Status hideSources />
            </div>
            <Box className={styles.chat}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text className={styles.heading}>{t('chat-tab.header')}</Text>
                    <Tooltip label={t('chat-tab.clear-chat')}>
                        <IconButton
                            minW="30px"
                            height="30px"
                            background="transparent"
                            aria-label="clear-chat"
                            icon={<ClearChat />}
                            isDisabled={conversation.length === 0}
                            onClick={clearChat}
                        />
                    </Tooltip>
                </Flex>

                <Box className={styles.messages}>
                    {conversation.map((props) => (
                        <ChatMessage
                            {...props}
                            key={props.id}
                            actions={<MessageActions directAnswer={props.directAnswer} />}
                        />
                    ))}
                    {conversation.length === 0 && (
                        <ChatMessage
                            key="default-message"
                            id="default-message"
                            role={MessageRole.ASSISTANT}
                            content={t('chat-tab.default-state')}
                        />
                    )}
                    <div ref={bottomRef} />
                </Box>
                <Box className={styles.inputWrapper}>
                    <ChatInput onSubmit={sendMessage} onAbort={abortMessage} isLoading={isLoading} />
                </Box>
            </Box>
        </div>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { ReactComponent as ReplyIcon } from '../../../../../assets/icons/reply.svg';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { openDirectAnswerChatModal } from '../../../../../redux/slices/modals/direct-answer-chat-modal-slice';
import { setGrowSidebarWidthWithAnimation } from '../../../../../redux/slices/resize/resize-slice';
import { getToastService } from '../../../../../services/toast/toast-service';
import { getLogger } from '../../../../../utils/logger';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import theme from '../../../../../styles/chakra/theme.module.scss';
import styles from './DirectAnswerChatAction.module.scss';

export const DirectAnswerChatAction: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleChatInputClick = () => {
        if (!directAnswer.question_id) {
            getLogger('App Chat Action').error(`question_id was not found in ${JSON.stringify(directAnswer)}`);
            getToastService().error('error.direct-answer-chat.open');
            return;
        }
        dispatch(openDirectAnswerChatModal(directAnswer.question_id));
        dispatch(setGrowSidebarWidthWithAnimation(parseInt(theme.editDirectAnswerWidth)));
    };

    return (
        <Tooltip label={t('button.chat.open')} placement="top-end" hasArrow>
            <InputGroup width="40%" className={styles.wrapper} onClick={handleChatInputClick}>
                <Input isReadOnly fontSize="small" className={styles.input} placeholder={t('button.chat.open')} />
                <InputRightElement height="100%" pointerEvents="none">
                    <ReplyIcon width={15} height={15} />
                </InputRightElement>
            </InputGroup>
        </Tooltip>
    );
};

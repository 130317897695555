import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import cx from 'clsx';

import { ActionType, ActionTypeV2, DirectAnswerStatus, IAnswerResponse } from '../../../api/types';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useDirectAnswer } from '../../../redux/hooks/answer/direct-answers-hooks';
import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { actionTypeToStyle } from '../../../utils/direct-answer';

import { AnswerActions } from './Actions/Actions';
import { DirectAnswerDescription } from './Description/DirectAnswerDescription';
import { shouldEmulateTypeWriterEffect } from './Description/TypeWriter/helpers';
import { CustomActionDirectAnswerIcon } from './Icon/CustomActionDirectAnswerIcon';
import { DirectAnswerIcon } from './Icon/DirectAnswerIcon';

import style from './DirectAnswer.module.scss';

export interface DirectAnswerProps {
    onAnswerAttributionClick?: (answer: IAnswerResponse) => void;
}

export const DirectAnswer: React.FC<DirectAnswerProps> = ({ onAnswerAttributionClick = () => false }) => {
    const [, { data: answersData }] = useAnswers();
    const { directAnswer } = useDirectAnswer();
    const { t } = useTranslation('translations');

    const [typewriterIsDone, setTypewriterIsDone] = useState(false);
    const typeWriterOnDone = useCallback(() => {
        setTypewriterIsDone(true);
    }, []);

    const action_type = useAppSelector((state) => state.question.action_type);
    const questionCustomAction = useAppSelector((state) => state.question.custom_action);
    const actionType = action_type || directAnswer?.action_type;
    const isTypeWriterEffectEnabled = shouldEmulateTypeWriterEffect(directAnswer);
    const isFailed = directAnswer?.status === DirectAnswerStatus.FAILED;
    const backgroundColor = isFailed ? '#FFEBEE' : questionCustomAction?.color;

    if (
        !actionType ||
        !answersData?.expect_direct_answer ||
        // If the action is search - will wait and check if the question is answerable
        (actionType === ActionType.SEARCH && !directAnswer?.is_answerable)
    ) {
        return null;
    }

    let shouldDisplayActions = directAnswer && directAnswer.status === DirectAnswerStatus.DONE;
    if (isTypeWriterEffectEnabled) {
        // If we're fake typing, we'll show the actions only once it's done
        shouldDisplayActions = shouldDisplayActions && typewriterIsDone;
    }

    const renderAnswerActionIcon = () => {
        if (actionType === ActionTypeV2.CUSTOM) {
            return questionCustomAction ? (
                <CustomActionDirectAnswerIcon
                    tooltip={questionCustomAction.tooltip}
                    iconUrl={questionCustomAction.icon_url}
                />
            ) : null;
        }

        return <DirectAnswerIcon actionType={actionType as ActionType} />;
    };

    const renderContent = () => {
        if (isFailed) {
            return (
                <div className={style.content}>
                    <AlertIcon className={style.alert} />
                    <Text fontSize="small" fontWeight={500}>
                        {t('direct-answer.failed')}
                    </Text>
                </div>
            );
        }

        return (
            <DirectAnswerDescription
                answersData={answersData}
                directAnswer={directAnswer}
                onAttributionClick={onAnswerAttributionClick}
                emulateTypeWriterEffect={isTypeWriterEffectEnabled}
                typewriterIsDone={typewriterIsDone}
                typeWriterOnDone={typeWriterOnDone}
            />
        );
    };

    return (
        <div
            style={{ backgroundColor }}
            className={cx(style.wrapper, actionTypeToStyle[actionType || ActionType.SEARCH])}
        >
            {renderContent()}
            <div className={style.footer}>
                {renderAnswerActionIcon()}
                {shouldDisplayActions && <AnswerActions directAnswer={directAnswer!} />}
            </div>
        </div>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';

import { ReactComponent as ReplyIcon } from '../../../../../assets/icons/reply.svg';
import { useAnswers } from '../../../../../redux/hooks/answer/answer-hooks';
import { openDirectAnswerChatModal } from '../../../../../redux/slices/modals/direct-answer-chat-modal-slice';
import { setGrowSidebarWidthWithAnimation } from '../../../../../redux/slices/resize/resize-slice';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import theme from '../../../../../styles/chakra/theme.module.scss';
import styles from './DirectAnswerChatAction.module.scss';

export const DirectAnswerChatAction: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const [, { data: answersData }] = useAnswers();

    const handleChatInputClick = async () => {
        dispatch(
            openDirectAnswerChatModal({
                originalDirectAnswer: directAnswer,
                conversationId: answersData?.question_id,
            })
        );
        dispatch(setGrowSidebarWidthWithAnimation(parseInt(theme.editDirectAnswerWidth)));
    };

    return (
        <Tooltip label={t('button.chat.open')} placement="top-end" hasArrow>
            <InputGroup width="40%" className={styles.wrapper} onClick={handleChatInputClick}>
                <Input fontSize="small" className={styles.input} placeholder={t('button.chat.open')} />
                <InputRightElement height="100%" pointerEvents="none">
                    <ReplyIcon width={15} height={15} />
                </InputRightElement>
            </InputGroup>
        </Tooltip>
    );
};

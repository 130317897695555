import React from 'react';

import { IDirectAnswerResult } from '../../../../api/types';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { ThumbDownAction } from '../../../Answers/Answer/Actions/Thumbs/ThumbDownAction';
import { ThumbUpAction } from '../../../Answers/Answer/Actions/Thumbs/ThumbUpAction';

import { CopyChatAction } from './Copy/CopyMessageAction';

import messageStyles from '../ChatMessage.module.scss';

export const MessageActions: React.FC<{ directAnswer?: IDirectAnswerResult }> = ({ directAnswer }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);

    return directAnswer ? (
        <div className={messageStyles.actions}>
            <ThumbUpAction answer={directAnswer} actionType={directAnswer.action_type ?? undefined} isDirectAnswer />
            {!isUatMode && (
                <ThumbDownAction
                    answer={directAnswer}
                    actionType={directAnswer.action_type ?? undefined}
                    isDirectAnswer
                />
            )}
            <CopyChatAction directAnswer={directAnswer} />
        </div>
    ) : null;
};

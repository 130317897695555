import { FC } from 'react';
import { Box } from '@chakra-ui/react';

import { IDirectAnswerResult } from '../../../../../api/types';
import { useAppSelector } from '../../../../../redux/hooks/app-hooks';
import { ThumbDownAction } from '../../../Answer/Actions/Thumbs/ThumbDownAction';
import { ThumbUpAction } from '../../../Answer/Actions/Thumbs/ThumbUpAction';
import { DirectAnswerCopyAction } from '../../Actions/Copy/DirectAnswerCopyAction';
import { DirectAnswerEditAction } from '../../Actions/Edit/DirectAnswerEditAction';

import styles from '../../../../Chat/Message/ChatMessage.module.scss';

export const DirectAnswerChatActions: FC<{ directAnswer?: IDirectAnswerResult; isLastMessage?: boolean }> = ({
    directAnswer,
    isLastMessage,
}) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    if (!directAnswer) {
        return null;
    }
    return (
        <Box className={styles.actions}>
            <DirectAnswerCopyAction directAnswer={directAnswer} />
            <ThumbUpAction answer={directAnswer} actionType={directAnswer.action_type ?? undefined} isDirectAnswer />
            {!isUatMode && (
                <ThumbDownAction
                    answer={directAnswer}
                    actionType={directAnswer.action_type ?? undefined}
                    isDirectAnswer
                />
            )}
            {isLastMessage && <DirectAnswerEditAction directAnswer={directAnswer} />}
        </Box>
    );
};

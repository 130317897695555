import { FC, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import { directAnswerEndpoint } from '../../../../api/endpoints/direct-answer/direct-answer-endpoint';
import { ChatType } from '../../../../api/types';
import { useChat } from '../../../../redux/hooks/answer/chat-hook';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { closeDirectAnswerChatModal } from '../../../../redux/slices/modals/direct-answer-chat-modal-slice';
import { setMinimizeSidebarWidthWithAnimation } from '../../../../redux/slices/resize/resize-slice';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { ChatEditPanel } from '../../../Chat/EditPanel/ChatEditPanel';
import { ChatInput } from '../../../Chat/Input/ChatInput';
import { ChatLoader } from '../../../Chat/Loader/ChatLoader';
import { MessageActions } from '../../../Chat/Message/Actions/MessageActions';
import { ChatMessage } from '../../../Chat/Message/ChatMessage';

import { DirectAnswerChatCloseButton } from './CloseButton/DirectAnswerChatCloseButton';
import { getLastAssistantMessage } from './utils';

import styles from './DirectAnswerChat.module.scss';

export const DirectAnswerChat: FC = () => {
    const { actionId } = useAppSelector((state) => state.modals.chatDirectAnswer);
    const { visible: isEditChatMessageVisible, message: editedMessage } = useAppSelector(
        (state) => state.modals.chatEdit
    );
    const { project } = useProject();
    const isInitialScrollPerformed = useRef<boolean>(false);
    const dispatch = useAppDispatch();

    const { isGenerating, isLoading, conversation, sendMessage } = useChat({
        actionId: actionId,
        chatType: ChatType.APP_CHAT,
    });
    const shouldShowEditPanel = useMemo(
        () =>
            isEditChatMessageVisible && conversation?.messages.find((m) => editedMessage?.message_id === m.message_id),
        [isEditChatMessageVisible, conversation?.messages, editedMessage?.message_id]
    );
    const bottomRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const isLoaded = bottomRef.current && conversation?.messages.length;
        if (!isLoaded) {
            return;
        }
        // Scroll to the bottom of the chat box when the component mounts
        const isScrolledToBottom = bottomRef.current.getBoundingClientRect().bottom <= window.innerHeight;
        if (isScrolledToBottom || !isInitialScrollPerformed.current || !isEditChatMessageVisible) {
            bottomRef.current.scrollIntoView({ behavior: 'auto' });
            isInitialScrollPerformed.current = true;
        }
    }, [conversation, isInitialScrollPerformed, isEditChatMessageVisible]);

    const handleChatClose = useCallback(async () => {
        dispatch(
            sendMetrics({
                event: MixpanelEvent.DIRECT_ANSWER_CLOSE_CHAT,
                meta: {
                    turns: Math.round((conversation?.messages.length ?? 0 - 1) / 2),
                    conversation_id: conversation?.conversation_id,
                },
            })
        );

        // Update the current direct answer value
        const lastMessage = getLastAssistantMessage(conversation?.messages);

        dispatch(
            directAnswerEndpoint.util.updateQueryData(
                'getDirectAnswer',
                { customer_project_id: project, question_id: actionId },
                (draft) => {
                    if (draft && lastMessage?.content) {
                        draft.answer = lastMessage.content;
                    }
                }
            )
        );

        dispatch(closeDirectAnswerChatModal());
        dispatch(setMinimizeSidebarWidthWithAnimation());
    }, [conversation, actionId, project, dispatch]);

    return (
        <Box className={styles.wrapper}>
            {shouldShowEditPanel ? (
                <ChatEditPanel chatType={ChatType.APP_CHAT} actionId={actionId} />
            ) : (
                <ChatLoader isLoading={isLoading}>
                    <Box className={styles.chat}>
                        <Box className={styles.messages}>
                            {conversation?.messages.map((message, index) => (
                                <ChatMessage
                                    {...message}
                                    key={message.message_id}
                                    actions={
                                        <MessageActions
                                            message={message}
                                            displayEditButton={index === conversation.messages.length - 1}
                                        />
                                    }
                                />
                            ))}
                            <div ref={bottomRef} />
                        </Box>
                        <Box className={styles.inputWrapper}>
                            <ChatInput onSubmit={sendMessage} isLoading={isGenerating} />
                        </Box>
                    </Box>
                </ChatLoader>
            )}

            <DirectAnswerChatCloseButton
                className={styles.closeButton}
                isDisabled={isGenerating}
                onClick={handleChatClose}
            />
        </Box>
    );
};

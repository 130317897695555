import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';

import { ChatType, MessageRole } from '../../api/types';
import { ReactComponent as ClearChat } from '../../assets/icons/clear-chat.svg';
import { AppToggle } from '../../clients/unleash/app-toggle-names';
import { useChat } from '../../redux/hooks/answer/chat-hook';
import { UpdateRequiredPlaceholder } from '../Placeholders/UpdateRequired/UpdateRequiredPlaceholder';
import { usePortConnection } from '../Sidebar/PortConnectionContext';
import { Status } from '../Status/Status';
import { Tooltip } from '../Tooltips/Tooltip';

import { ChatInput } from './Input/ChatInput';
import { ChatLoader } from './Loader/ChatLoader';
import { MessageActions } from './Message/Actions/MessageActions';
import { ChatMessage } from './Message/ChatMessage';

import styles from './Chat.module.scss';

export const Chat: React.FC = () => {
    const { t } = useTranslation('translations');
    const { isGenerating, isLoading, conversation, sendMessage, clearChat, abortMessage } = useChat({
        chatType: ChatType.TOOL_CHAT,
    });
    const shouldShowChatFilters = useFlag(AppToggle.CHAT_TAB_FILTERS);

    const { hasContext, connected } = usePortConnection();
    const bottomRef = useRef<HTMLDivElement>(null);
    const isInitialScrollPerformed = useRef<boolean>(false);
    const displayUpdateRequiredPlaceholder = hasContext && !connected;

    useLayoutEffect(() => {
        const isLoaded = bottomRef.current && conversation?.messages.length;
        if (!isLoaded) {
            return;
        }
        // Scroll to the bottom of the chat box when the component mounts
        const isScrolledToBottom = bottomRef.current.getBoundingClientRect().bottom <= window.innerHeight;
        if (isScrolledToBottom || !isInitialScrollPerformed.current) {
            bottomRef.current.scrollIntoView({ behavior: 'auto' });
            isInitialScrollPerformed.current = true;
        }
    }, [conversation, bottomRef, isInitialScrollPerformed]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Status hideSources={!shouldShowChatFilters} />
            </div>
            {displayUpdateRequiredPlaceholder ? (
                <UpdateRequiredPlaceholder />
            ) : (
                <Box className={styles.chat}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text className={styles.heading}>{t('chat-tab.header')}</Text>
                        <Tooltip label={t('chat-tab.clear-chat')}>
                            <IconButton
                                minW="30px"
                                height="30px"
                                background="transparent"
                                aria-label="clear-chat"
                                icon={<ClearChat />}
                                isDisabled={conversation?.messages.length === 0}
                                onClick={clearChat}
                            />
                        </Tooltip>
                    </Flex>
                    <ChatLoader isLoading={isLoading}>
                        <Box className={styles.messages}>
                            {conversation?.messages.map((message) => (
                                <ChatMessage
                                    {...message}
                                    key={message.message_id}
                                    actions={<MessageActions message={message} />}
                                    userQuery={
                                        conversation?.messages.find(
                                            (m) => m.turn_id === message.turn_id && m.role === MessageRole.USER
                                        )?.content
                                    }
                                />
                            ))}
                            {(!conversation || conversation?.messages.length === 0) && (
                                <ChatMessage
                                    key="default-message"
                                    message_id="default-message"
                                    turn_id="default-message"
                                    role={MessageRole.ASSISTANT}
                                    content={t('chat-tab.default-state')}
                                />
                            )}
                            <div ref={bottomRef} />
                        </Box>
                    </ChatLoader>
                    <Box className={styles.inputWrapper}>
                        <ChatInput
                            onSubmit={sendMessage}
                            onAbort={abortMessage}
                            isLoading={isGenerating}
                            disabled={isLoading}
                        />
                    </Box>
                </Box>
            )}
        </div>
    );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@chakra-ui/react';

export const DirectAnswerChatCloseButton: FC<Pick<ButtonProps, 'isDisabled' | 'onClick'>> = ({
    isDisabled,
    onClick,
}) => {
    const { t } = useTranslation('translations');

    return (
        <Button
            isDisabled={isDisabled}
            onClick={onClick}
            variant="primary"
            fontSize="s"
            borderRadius={20}
            padding="0.3"
            minHeight="28px"
            height="fit-content"
        >
            {t('chat.close-button')}
        </Button>
    );
};

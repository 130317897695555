import { FC, useCallback, useLayoutEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import { directAnswerEndpoint } from '../../../../api/endpoints/direct-answer/direct-answer-endpoint';
import { ChatType, MessageRole } from '../../../../api/types';
import { useChat } from '../../../../redux/hooks/answer/chat-hook';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/app-hooks';
import { useProject } from '../../../../redux/hooks/settings-hooks';
import { closeDirectAnswerChatModal } from '../../../../redux/slices/modals/direct-answer-chat-modal-slice';
import { setMinimizeSidebarWidthWithAnimation } from '../../../../redux/slices/resize/resize-slice';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { ChatInput } from '../../../Chat/Input/ChatInput';
import { ChatMessage } from '../../../Chat/Message/ChatMessage';

import { DirectAnswerChatActions } from './Actions/DirectAnswerChatActions';
import { DirectAnswerChatCloseButton } from './CloseButton/DirectAnswerChatCloseButton';

import styles from './DirectAnswerChat.module.scss';

export const DirectAnswerChat: FC = () => {
    const { data: chatArgs } = useAppSelector((state) => state.modals.chatDirectAnswer);
    const dispatch = useAppDispatch();
    const { project } = useProject();
    const isInitialScrollPerformed = useRef<boolean>(false);

    const { isLoading, conversation, chatId, sendMessage, isReachedLimit } = useChat({
        ...chatArgs,
        chatType: ChatType.DIRECT_ANSWER_CHAT,
    });
    const bottomRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        // Scroll to the bottom of the chat box when the component mounts
        if (bottomRef.current && conversation.length) {
            const isScrolledToBottom = bottomRef.current.getBoundingClientRect().bottom <= window.innerHeight;
            if (isScrolledToBottom || !isInitialScrollPerformed.current) {
                bottomRef.current.scrollIntoView({ behavior: 'auto' });
                isInitialScrollPerformed.current = true;
            }
        }
    }, [conversation, isInitialScrollPerformed]);

    const getLastAssistantMessage = useCallback(() => {
        for (let i = conversation.length - 1; i >= 0; i--) {
            const message = conversation[i];
            if (message.role === MessageRole.ASSISTANT && !message.isFailed) {
                return message;
            }
        }
        return undefined;
    }, [conversation]);

    const handleChatClose = useCallback(async () => {
        dispatch(
            sendMetrics({
                event: MixpanelEvent.DIRECT_ANSWER_CLOSE_CHAT,
                meta: {
                    turns: Math.round((conversation.length - 1) / 2),
                    chatId,
                },
            })
        );

        // Update the current direct answer value
        const lastMessage = getLastAssistantMessage();

        dispatch(
            directAnswerEndpoint.util.upsertQueryData(
                'getDirectAnswer',
                { customer_project_id: project, question_id: chatArgs?.conversationId },
                chatArgs?.originalDirectAnswer
                    ? {
                          ...chatArgs?.originalDirectAnswer,
                          answer: lastMessage?.content ?? chatArgs?.originalDirectAnswer?.answer,
                          disableFakeType: true,
                      }
                    : undefined
            )
        );

        dispatch(closeDirectAnswerChatModal());
        dispatch(setMinimizeSidebarWidthWithAnimation());
    }, [conversation, chatArgs, project, chatId, dispatch, getLastAssistantMessage]);

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.chat}>
                <Box className={styles.messages}>
                    {conversation.map((props, index) => (
                        <ChatMessage
                            {...props}
                            key={props.id}
                            actions={
                                <DirectAnswerChatActions
                                    isLastMessage={index === conversation.length - 1}
                                    directAnswer={props.directAnswer}
                                />
                            }
                        />
                    ))}
                    <div ref={bottomRef} />
                </Box>
                <Box className={styles.inputWrapper}>
                    <ChatInput onSubmit={sendMessage} isLoading={isLoading} disabled={isReachedLimit} />
                </Box>
            </Box>
            <DirectAnswerChatCloseButton isDisabled={isLoading} onClick={handleChatClose} />
        </Box>
    );
};

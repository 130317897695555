import Quill from 'quill';

export const handleQuillSelection = (quill: Quill) => {
    // Each browser engine has a different implementation for retrieving the Range
    const getNativeRange = (rootNode: any) => {
        try {
            // @ts-ignore
            if (document.createElement('div').attachShadow({ mode: 'open' }).getSelection) {
                // In Chromium, the shadow root has a getSelection function which returns the range
                return rootNode.getSelection().getRangeAt(0);
            } else {
                const selection = window.getSelection();
                if (!selection) {
                    return null;
                }
                // @ts-ignore
                if (selection.getComposedRanges) {
                    // @ts-ignore
                    return selection.getComposedRanges(rootNode)[0];
                } else {
                    return selection.getRangeAt(0);
                }
            }
        } catch {
            return null;
        }
    };

    /**
     * Original implementation uses document.active element which does not work in Native Shadow.
     * Replace document.activeElement with shadowRoot.activeElement
     **/
    quill.selection.hasFocus = function () {
        const rootNode = quill.root.getRootNode() as Document;
        return rootNode.activeElement === quill.root;
    };

    /**
     * Original implementation uses document.getSelection which does not work in Native Shadow.
     * Replace document.getSelection with shadow dom equivalent (different for each browser)
     **/
    quill.selection.getNativeRange = function () {
        const rootNode = quill.root.getRootNode();
        const nativeRange = getNativeRange(rootNode);
        return nativeRange ? quill.selection.normalizeNative(nativeRange) : null;
    };

    /**
     * Original implementation relies on Selection.addRange to programmatically set the range, which does not work
     * in Webkit with Native Shadow. Selection.addRange works fine in Chromium and Gecko.
     **/
    quill.selection.setNativeRange = function (startNode: Node | null, startOffset?: number) {
        let endNode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : startNode;
        let endOffset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : startOffset;
        let force = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
        if (
            startNode != null &&
            (quill.selection.root.parentNode == null || startNode.parentNode == null || endNode.parentNode == null)
        ) {
            return;
        }
        let selection = document.getSelection();
        if (selection == null) {
            return;
        }
        if (startNode != null) {
            if (!quill.selection.hasFocus()) quill.selection.root.focus();
            let native = quill.selection?.getNativeRange()?.native;
            if (
                native == null ||
                force ||
                startNode !== native.startContainer ||
                startOffset !== native.startOffset ||
                endNode !== native.endContainer ||
                endOffset !== native.endOffset
            ) {
                // @ts-ignore
                if (startNode.tagName === 'BR') {
                    // @ts-ignore
                    startOffset = [].indexOf.call(startNode.parentNode!.childNodes, startNode);
                    startNode = startNode.parentNode;
                }
                if (endNode.tagName === 'BR') {
                    // @ts-ignore
                    endOffset = Array.prototype.indexOf.call(endNode.parentNode.childNodes, endNode);
                    endNode = endNode.parentNode;
                }
                selection.setBaseAndExtent(startNode!, startOffset!, endNode, endOffset);
            }
        } else {
            selection.removeAllRanges();
            quill.selection.root.blur();
            document.body.focus();
        }
    };
};

import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { fetchInBackground } from '../../../services/background-fetch/background-fetch-service';
import { isContentMode } from '../../../utils/extension-mode';
import { getLogger } from '../../../utils/logger';
import { IDirectAnswer } from '../../types';

export const GENERATIVE_ANSWER_EVENT = 'generativeAnswer';

const _fetch = isContentMode() ? fetchInBackground : window.fetch.bind(window);
const logger = getLogger('direct-answer-stream-sse');

export const directAnswerStreamSse = (
    token: string,
    questionId: string,
    customerProjectId: string,
    abortController: AbortController,
    onDirectAnswerLoaded?: (directAnswer: IDirectAnswer | null) => void
) => {
    const url = new URL(
        `${process.env.REACT_APP_DIRECT_ANSWER_URL}/direct_answers/${customerProjectId}/${questionId}/stream`
    ).toString();

    const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'text/event-stream; charset=utf-8',
        Connection: 'keep-alive',
        'Cache-Control': 'no-cache',
    };

    return fetchEventSource(url, {
        headers,
        signal: abortController.signal,
        openWhenHidden: true, // this prevents to pause the stream when the tab is not visible/active
        fetch: _fetch,
        async onopen(response) {
            if (response?.ok && response?.headers?.get('content-type')?.includes(EventStreamContentType)) {
                return; // everything's good
            }

            logger.warn('Invalid response', response, 'will NOT create a stream.');

            throw new Error('Invalid response');
        },
        onerror: (error) => {
            throw error;
        },
        onmessage: ({ event, data }) => {
            if (!data || event !== GENERATIVE_ANSWER_EVENT) {
                return;
            }

            let directAnswer: IDirectAnswer;

            try {
                directAnswer = JSON.parse(data) as IDirectAnswer;
            } catch (error) {
                logger.error('Error while parsing direct answer data', error);
                return;
            }

            onDirectAnswerLoaded?.(directAnswer);
        },
    });
};

import { IMessage, MessageRole, MessageStatus } from '../../../../api/types';

export const getLastAssistantMessage = (messages?: IMessage[]): IMessage | undefined => {
    let message: IMessage | undefined = undefined;

    if (messages?.length) {
        for (let i = messages.length - 1; i >= 0; i--) {
            const currentMessage = messages[i];
            if (currentMessage.role === MessageRole.ASSISTANT && currentMessage.status !== MessageStatus.FAILED) {
                message = currentMessage;
                break;
            }
        }
    }

    return message;
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useSendMessageMutation } from '../../../api/endpoints/chat-endpoint';
import { useLazyGetDirectAnswerQuery } from '../../../api/endpoints/direct-answer/direct-answer-endpoint';
import { usageMetricsEndpoint } from '../../../api/endpoints/usage-metrics-endpoint';
import {
    ChatType,
    DirectAnswerStatus,
    IDirectAnswerResult,
    Message,
    MessageRole,
    UsageMetricsEventType,
} from '../../../api/types';
import { handleStreamEntry, setAbortMessage } from '../../../redux/slices/conversation/conversation-slice';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import {
    addMessage,
    clearConversation,
    getCurrentConversationId,
    setupConversation,
    storeConversation,
} from '../../thunks/chat-thunk';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch, useAppSelector } from '../app-hooks';
import { useProject } from '../settings-hooks';

const finishedStatus = [DirectAnswerStatus.DONE, DirectAnswerStatus.FAILED, DirectAnswerStatus.ABORTED];
export interface ChatArgs {
    originalDirectAnswer?: IDirectAnswerResult;
    conversationId?: string;
    chatType: ChatType;
}

export const useChat = (args: ChatArgs) => {
    const { conversationId: appConversationId, originalDirectAnswer, chatType } = args ?? {};

    const [conversationId, setConversationId] = useState(appConversationId ?? uuid());
    const [chatId] = useState(uuid());
    const [abortedMessageId, setAbortedMessageId] = useState<string>();

    const conversationState = useAppSelector((state) => state.chat[conversationId]);
    const conversation = useMemo(() => conversationState ?? [], [conversationState]);

    const { project } = useProject();
    const dispatch = useAppDispatch();

    const [triggerSendMessage, { data: messageResponse }] = useSendMessageMutation();
    const [triggerGetDirectAnswer, { data: directAnswer }] = useLazyGetDirectAnswerQuery();

    useEffect(() => {
        const getConversationId = async () => {
            if (chatType === ChatType.CHAT_TAB) {
                const currentConversationId = await getCurrentConversationId(chatType);
                if (!currentConversationId) {
                    return;
                }

                setConversationId(currentConversationId);
            }
        };

        getConversationId();
    }, [chatType]);

    useEffect(() => {
        const handleInitialize = () => {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.DIRECT_ANSWER_OPEN_CHAT,
                    meta: {
                        chatId,
                        chatType,
                    },
                })
            );
            // Setup new conversation
            dispatch(
                setupConversation({
                    conversationId,
                    chatType,
                    conversationSetup: originalDirectAnswer
                        ? [
                              {
                                  id: conversationId,
                                  content: originalDirectAnswer?.answer ?? '',
                                  role: MessageRole.ASSISTANT,
                                  directAnswer: originalDirectAnswer,
                                  attributions: originalDirectAnswer.attributions,
                              },
                          ]
                        : [],
                })
            );
        };
        handleInitialize();
    }, [conversationId, originalDirectAnswer, chatId, chatType, dispatch]);

    const isCurrentMessageAborted = useMemo(
        () => abortedMessageId === messageResponse?.question_id,
        [messageResponse, abortedMessageId]
    );

    const sendMessage = useCallback(
        async (content: string) => {
            if (directAnswer && directAnswer?.status !== DirectAnswerStatus.DONE && !isCurrentMessageAborted) {
                return;
            }

            const newMessage: Message = { role: MessageRole.USER, content, id: uuid(), time: new Date() };

            dispatch(addMessage({ conversationId, chatType, message: newMessage }));

            await triggerSendMessage({
                content,
                conversation_id: conversationId,
                chat_history: conversation,
                customer_project_id: project,
                action_id: originalDirectAnswer?.action_id,
                chat_type: chatType,
            });
        },
        [
            triggerSendMessage,
            dispatch,
            isCurrentMessageAborted,
            chatType,
            conversation,
            directAnswer,
            conversationId,
            project,
            originalDirectAnswer,
        ]
    );

    useEffect(() => {
        const messageId = messageResponse?.question_id;

        if (!messageId || messageResponse?.conversation_id !== conversationId) {
            return;
        }

        if (chatType === ChatType.CHAT_TAB) {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_SEND_MESSAGE,
                    meta: {
                        chatId,
                        chatType,
                        turnId: messageId,
                    },
                })
            );
        }

        triggerGetDirectAnswer({
            question_id: messageId,
            customer_project_id: project,
        });
    }, [messageResponse, chatId, chatType, project, conversationId, dispatch, triggerGetDirectAnswer]);

    useEffect(() => {
        const messageId = messageResponse?.question_id;

        if (!messageId || messageResponse?.conversation_id !== conversationId) {
            return;
        }

        // Message was aborted. Will ignore the next sse events.
        if (isCurrentMessageAborted) {
            dispatch(storeConversation({ conversationId, chatType }));
            return;
        }

        dispatch(handleStreamEntry({ messageId, directAnswer, conversationId }));

        // Store last message in local storage
        if (directAnswer?.status === DirectAnswerStatus.DONE) {
            dispatch(storeConversation({ conversationId, chatType }));
        }
    }, [directAnswer, conversationId, messageResponse, chatType, isCurrentMessageAborted, dispatch]);

    const clearChat = useCallback(() => {
        dispatch(clearConversation({ conversationId, chatType }));
        setConversationId(uuid());
    }, [conversationId, chatType, dispatch]);

    const abortMessage = useCallback(() => {
        const messageId = messageResponse?.question_id;

        if (!messageId || messageResponse?.conversation_id !== conversationId) {
            return;
        }

        const meta = {
            chatId,
            conversationId,
            chatType,
            turnId: messageId,
        };

        dispatch(
            sendMetrics({
                event: MixpanelEvent.CHAT_ABORT_MESSAGE,
                meta,
            })
        );

        dispatch(
            usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                type: UsageMetricsEventType.CHAT_MESSAGE_ABORT,
                meta: {
                    ...meta,
                    output: conversation[conversation.length - 1].content,
                    timestamp: Date.now(),
                },
            })
        );

        setAbortedMessageId(messageId);
        dispatch(setAbortMessage({ messageId, conversationId }));
    }, [messageResponse, conversationId, chatId, chatType, conversation, dispatch]);

    const isLoading = useMemo(() => {
        const currentMessage = conversation[conversation.length - 1];
        const status = currentMessage?.directAnswer?.status;

        if (!status) {
            return true;
        }

        return !finishedStatus.includes(status) && !currentMessage?.isAborted;
    }, [conversation]);

    return useMemo(
        () => ({
            conversation,
            chatId,
            sendMessage,
            clearChat,
            abortMessage,
            isLoading,
            isReachedLimit: directAnswer && directAnswer.status === DirectAnswerStatus.FAILED,
        }),
        [directAnswer, conversation, chatId, isLoading, clearChat, sendMessage, abortMessage]
    );
};

import React, { useMemo } from 'react';
import { useVariant } from '@unleash/proxy-client-react';

import { ActionType, IDirectAnswerResult } from '../../../../api/types';
import { AppVariants } from '../../../../clients/unleash/app-variants-names';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { isPortalWebMode } from '../../../../utils/extension-mode';
import { ThumbDownAction } from '../../Answer/Actions/Thumbs/ThumbDownAction';
import { ThumbUpAction } from '../../Answer/Actions/Thumbs/ThumbUpAction';

import { DirectAnswerChatAction } from './Chat/DirectAnswerChatAction';
import { DirectAnswerCopyAction } from './Copy/DirectAnswerCopyAction';
import { DirectAnswerEditAction } from './Edit/DirectAnswerEditAction';
import { DirectAnswerShareAction } from './Share/DirectAnswerShareAction';

import styles from './Actions.module.scss';

export interface IAnswerActionsProps {
    directAnswer: IDirectAnswerResult;
}

export const AnswerActions: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const chatAvailability = useVariant(AppVariants.DIRECT_ANSWER_CHAT);

    const isActionIsSearch = directAnswer?.action_type === ActionType.SEARCH;

    const isChatEnabled: boolean = useMemo(() => {
        if (!chatAvailability.enabled) {
            return false;
        }
        const availability = chatAvailability.payload?.value;
        switch (availability) {
            case 'all': {
                // Will show in all types
                return true;
            }
            case 'generative':
                // Will show only if action is search - AKA generative
                return isActionIsSearch;
            case 'apps': {
                // Will show only if action is NOT search - AKA apps
                return !isActionIsSearch;
            }
            default: {
                return false;
            }
        }
    }, [chatAvailability, isActionIsSearch]);

    if (isPortalWebMode()) {
        return (
            <div className={styles.wrapperWebMode}>
                <div className={styles.actions}>
                    <DirectAnswerEditAction directAnswer={directAnswer} />
                    {isActionIsSearch && <DirectAnswerCopyAction includeAttributionLinks directAnswer={directAnswer} />}
                    <DirectAnswerCopyAction directAnswer={directAnswer} />
                    {isActionIsSearch && <DirectAnswerShareAction directAnswer={directAnswer} />}
                </div>
                {isChatEnabled && <DirectAnswerChatAction directAnswer={directAnswer} />}
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.actions}>
                <DirectAnswerEditAction directAnswer={directAnswer} />
                {isActionIsSearch && <DirectAnswerCopyAction includeAttributionLinks directAnswer={directAnswer} />}
                <DirectAnswerCopyAction directAnswer={directAnswer} />
                {isActionIsSearch && <DirectAnswerShareAction directAnswer={directAnswer} />}
                <ThumbUpAction
                    answer={directAnswer}
                    actionType={directAnswer.action_type || undefined}
                    isDirectAnswer
                />
                {!isUatMode && (
                    <ThumbDownAction
                        answer={directAnswer}
                        actionType={directAnswer.action_type || undefined}
                        isDirectAnswer
                    />
                )}
            </div>
            {isChatEnabled && <DirectAnswerChatAction directAnswer={directAnswer} />}
        </div>
    );
};

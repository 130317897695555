import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { closeDirectAnswerChatModal } from '../../redux/slices/modals/direct-answer-chat-modal-slice';
import { getToastService } from '../../services/toast/toast-service';
import { baseApi } from '../base-api';
import { ApiTagTypes, IChatRequestParams, IChatResponse, ResponseStatus } from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const chatEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation<IChatResponse, IChatRequestParams>({
            queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
                const { chat_history, customer_project_id, action_id, content, conversation_id, chat_type } = arg;

                const apiUrl = '/v2/chat/message';

                const chatResponse = await baseQuery({
                    url: apiUrl,
                    method: 'POST',
                    body: {
                        chat_history: chat_history.map((message) => ({ role: message.role, content: message.content })),
                        content,
                        action_id,
                        customer_project_id,
                        conversation_id,
                        chat_type,
                    },
                });

                const { data, error, meta } = chatResponse as QueryReturnValue<
                    IChatResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.answers', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    dispatch(closeDirectAnswerChatModal());

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                } else {
                    return {
                        data: {
                            question_id: data.question_id || '',
                            conversation_id,
                        },
                    };
                }
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});

export const { useSendMessageMutation } = chatEndpoint;

import { DirectAnswerStatus, IDirectAnswerResult } from '../../../../../api/types';

export const shouldEmulateTypeWriterEffect = (directAnswer: IDirectAnswerResult | undefined): boolean => {
    if (directAnswer?.disableFakeType) {
        return false;
    }
    const shouldEmulateTypeWriterEffect =
        (!directAnswer?.fromWebSocket && directAnswer?.status === DirectAnswerStatus.DONE) ||
        directAnswer?.forceFakeType;

    return Boolean(shouldEmulateTypeWriterEffect);
};

import React, { MouseEventHandler, useCallback, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'clsx';
import debounce from 'lodash.debounce';

import { IAttributionsAnswer } from '../../../../../api/types';
import { ReactComponent as NextIcon } from '../../../../../assets/icons/answers/document-mention-next-item.svg';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { setActiveAnswer } from '../../../../../redux/slices/answers/answers-slice';
import { sendDirectAnswerMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';

import { AttributionItem } from './AttributionItem/AttributionItem';

import styles from './Attribution.module.scss';
import itemStyles from './AttributionItem/AttributionItem.module.scss';

export interface AttributionProps {
    className?: string;
    answers: IAttributionsAnswer[];
    onAttributionClick?: (answer: IAttributionsAnswer) => void;
    disableDimmer?: boolean;
}

const maxItemWidth = parseInt(itemStyles.itemMaxWidth, 10);
const debounceTime = 50;

export const Attribution: React.FC<AttributionProps> = ({ className, answers, onAttributionClick, disableDimmer }) => {
    const dispatch = useAppDispatch();

    const [contentOverflows, setContentOverflows] = useState(false);
    const [scrolledToStart, setScrolledToStart] = useState(true);
    const [scrolledToEnd, setScrolledToEnd] = useState(false);
    const [dragStart, setDragStart] = useState(false);
    const startPositionRef = useRef({ startX: 0, scrollLeft: 0 });
    const preventOnClickRef = useRef(false);
    const listRef = useRef<HTMLUListElement | null>(null);
    const clickedAnswerRef = useRef<IAttributionsAnswer | null>(null);

    const handleResize = useCallback(
        ({ target }: ResizeObserverEntry) => {
            const ifContentOverflows = target.scrollWidth > target.clientWidth;

            // change state only if it's different
            if (contentOverflows !== ifContentOverflows) {
                setContentOverflows(ifContentOverflows);
            }
        },
        [contentOverflows]
    );

    useResizeObserver(listRef, handleResize);

    useLayoutEffect(() => {
        const listElement = listRef.current;

        const handleListScroll = debounce(() => {
            if (listRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
                const scrolledToEnd = scrollLeft + clientWidth >= scrollWidth;

                setScrolledToEnd(scrolledToEnd);
                setScrolledToStart(scrollLeft === 0);
            }
        }, debounceTime);

        if (listElement) {
            listElement.addEventListener('scroll', handleListScroll);
        }

        return () => {
            if (listElement) {
                listElement.removeEventListener('scroll', handleListScroll);
            }

            handleListScroll.cancel();
        };
    }, []);

    const setClickedAnswer = (answer: IAttributionsAnswer) => {
        clickedAnswerRef.current = answer;
    };

    const handleMouseDown: MouseEventHandler = (e) => {
        if (contentOverflows) {
            setDragStart(true);
            preventOnClickRef.current = false;

            if (listRef.current) {
                startPositionRef.current.startX = e.pageX - -(listRef.current?.offsetLeft ?? 0);
                startPositionRef.current.scrollLeft = listRef.current.scrollLeft;
            }
        }
    };

    const handleMouseUp: MouseEventHandler = () => {
        if (contentOverflows) {
            setDragStart(false);
        }

        if (!preventOnClickRef.current && clickedAnswerRef.current) {
            if (onAttributionClick) {
                onAttributionClick(clickedAnswerRef.current);
            } else {
                dispatch(setActiveAnswer(clickedAnswerRef.current.doc_id));
            }

            dispatch(
                sendDirectAnswerMetrics(MixpanelEvent.DIRECT_ANSWER_VIEW_ATTRIBUTION_LINK, {
                    answer: clickedAnswerRef.current,
                    inline: false,
                })
            );
        }

        clickedAnswerRef.current = null;
    };

    const handleMouseMove: MouseEventHandler = (e) => {
        if (contentOverflows && dragStart && listRef.current) {
            e.preventDefault();
            // start/previous position
            const startX = startPositionRef.current.startX;
            const startScrollLeft = startPositionRef.current.scrollLeft;

            // calculate new position
            const currentPositionX = e.pageX - (listRef.current?.offsetLeft ?? 0);
            const walk = (currentPositionX - startX) * 1.1;

            // set new position
            listRef.current.scrollLeft = startScrollLeft - walk;

            // Prevent onClick event in case of drag
            preventOnClickRef.current = !!walk;
        }
    };

    const handleMouseLeave: MouseEventHandler = () => {
        setDragStart(false);
    };

    const handlePrevButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (listRef.current) {
            listRef.current.scrollLeft = listRef.current.scrollLeft - maxItemWidth;
        }
    };

    const handleNextButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (listRef.current) {
            listRef.current.scrollLeft = listRef.current.scrollLeft + maxItemWidth;
        }
    };

    return (
        <div className={cx({ [styles.dimmer]: answers.length > 0 && !disableDimmer }, className)}>
            <button
                className={cx(styles.slideButton, { [styles.displayPrevButton]: contentOverflows && !scrolledToStart })}
                onClick={handlePrevButtonClick}
            >
                <NextIcon />
            </button>
            <ul
                className={cx(styles.list, {
                    [styles.dragging]: dragStart,
                    [styles.sliding]: !dragStart,
                })}
                ref={listRef}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                {answers.map((attribution, idx) => (
                    <AttributionItem
                        key={attribution.doc_id}
                        refNumber={idx + 1}
                        attribution={attribution}
                        onMouseDown={setClickedAnswer}
                    />
                ))}
            </ul>
            <button
                className={cx(styles.slideButton, { [styles.displayNextButton]: contentOverflows && !scrolledToEnd })}
                onClick={handleNextButtonClick}
            >
                <NextIcon />
            </button>
        </div>
    );
};
